import React from "react";
import PropTypes from "prop-types";
import { Router } from "@reach/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//import ProfileComponent from "../containers/Profile/Profile";
import Login from "../containers/Login/Login";
import DashboardComponent from "../containers/Dashboard/Dashboard";
import PrivateRoute from "../PrivateRoute";
import SEO from "../seo";
import LoaderComponent from "../components/LoaderComponent/LoaderComponent";
import NotifyMessageComponent from "../components/NotifyMessageComponent/NotifyMessageComponent";

//ACTIONS
import { setNotification } from "../actions/status.actions";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: null
    };
  }

  render() {
    return (
      <>
        <SEO />
        <Router basepath="/app">
          {/* <PrivateRoute path="/admin/profile" component={ProfileComponent} /> */}
          <PrivateRoute path="/admin/dashboard" component={DashboardComponent} />
          <PrivateRoute path="/login" component={Login} />
          <PrivateRoute path="/" component={Login} />
        </Router>
        <NotifyMessageComponent
          text={this.props.notification.text}
          type={this.props.notification.type}
          show={this.props.notification.show}
        />
        <LoaderComponent />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.status.notification
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNotification
    },
    dispatch
  );

App.propTypes = {
  notification: PropTypes.object,
  setNotification: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
